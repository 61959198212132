<!--
 * ======================================
 * 说明： 移动端登录
 * 作者： Silence
 * 文件： login.vue
 * 日期： 2023/7/21 21:49
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
    <div class="w-full h-full" v-loading="loading"></div>
<!--  <div class="mobile-layout">-->
<!--    <div-->
<!--      class="flex item-center justify-center h-full"-->
<!--      style="flex-direction: column"-->
<!--    >-->
<!--      <img class="mx-auto h-24 w-auto" src="@/assets/logo.png" alt="Workflow" />-->
<!--      <h2 class="mt-6 text-center text-xl font-extrabold text-gray-900">-->
<!--        智慧校园综合素质评价管理系统-->
<!--      </h2>-->
<!--      <p class="mt-2 text-center text-sm text-gray-200">-->
<!--        欢迎登录学生综合素质评价系统-->
<!--      </p>-->
<!--      <div class="mobile-login-main">-->
<!--        <el-form :model="formData" :rules="rules" ref="formRef">-->
<!--          <el-form-item label="手机号/账号/学号" prop="username">-->
<!--            <el-input v-model="formData.username"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="登录密码" prop="password">-->
<!--            <el-input type="password" v-model="formData.password"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-button type="primary" class="w-full" @click="handleSubmitForm">登 录</el-button>-->
<!--        </el-form>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>
<script>
import * as dd from "dingtalk-jsapi";
export default {
  name: "mobileLogin",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      formData: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入手机号/账号/学号",
            trigger: "blur",
          }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          }
        ]
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    
    // 钉钉自动登录
    this.autoLoginDing()   // 自动钉钉登录
    
  },
  methods: {
    autoLoginDing(){
      const _this = this;
      _this.loading = true;
      dd.ready(function () {
        // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
        dd.runtime.permission.requestAuthCode({
          corpId: window.corpId,
          onSuccess: function (result) {
            _this.loading = false;
            console.log(result);
            // alert("result" + JSON.stringify(result));
            _this.$cloud
              .login("ding/login?code=" + result.code, {})
              .then((res) => {
                _this.$cloud.go("/mobile/index/index");
              })
              .catch((error) => {
                _this.$message.error(error);
                // alert("error" + JSON.stringify(error));
                console.log("error", error);
              });
          },
          onFail: function (err) {
            _this.loading = false;
            console.log(err);
            alert("登录失败" + JSON.stringify(err));
          },
        });
      });
    },
    
    // 登录
    handleSubmitForm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$cloud
            .login("login/index", this.formData)
            .then((res) => {
              if (res.type !== "student" && res.role === "admin") {
                this.$cloud.go("/mobile/index/index");
              } else {
                this.$message.error('登录失败')
              }
            })
            .catch(err=>{
              this.$message.error(err)
            });
        }
      })

    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-layout {
  width: 100%;
  height: 100vh;
  background: url("~@/assets/southeast.jpg") center no-repeat;
  background-size: cover;
  .mobile-login-main {
    width: 80%;
    background-color: white;
    margin: 1rem auto 0;
    border-radius: 0.8rem;
    padding: 1rem;
  }
}
</style>
